
body {
	&:after {
		display:block;
		content:'';
		position: fixed;
		background-color: #eee;
		background-repeat: no-repeat;
		background-position: center center;
		background-image: url(/source/dist/images/loader.gif);
		width: 100%;
		height:100%;
		z-index:2000;
		top:0px;
		left:0px;
	}
}
